import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { ISchool } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { STUDENT_FEES_API_URL } from 'store/ApiUrls';
import { IStudent, IStudentCourses } from 'pages/students/_store/types';

export interface IStudentFee {
  id: number;
  studentId: IStudent;
  feeName: string;
  feeAmount: number;
  school: ISchool;
  courseId: IStudentCourses;
}

interface IStudentFeeState {
  studentFees: IStudentFee[];
  phase: string;
}

type TActionAllState = IStudentFeeState & {
  id: number;
  school: ISchool;
  student: IStudent;
  studentFee: IStudentFee;
  studentFeeInfo: Partial<IStudentFee>;
};

export const actionTypes = {
  PULL_STUDENT_FEES: 'studentFees/PULL_STUDENT_FEES',
  SET_STUDENT_FEES: 'studentFees/SET_STUDENT_FEES',
  SET_PHASE: 'studentFees/SET_PHASE'
};

export const initialState: IStudentFeeState = {
  studentFees: [],
  phase: null
};

export const studentFeesSelector = createSelector(
  (state: IStudentFeeState) => objectPath.get(state, ['studentFees', 'studentFees']),
  (studentFees: IStudentFee[]) => studentFees
);

export const studentFeesPhaseSelector = createSelector(
  (state: IStudentFeeState) => objectPath.get(state, ['studentFees', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'studentFees' },
  (state: IStudentFeeState = initialState, action: IAction<TActionAllState>): IStudentFeeState => {
    switch (action.type) {
      case actionTypes.SET_STUDENT_FEES: {
        const { studentFees } = action.payload;
        return { ...state, studentFees };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const studentFeesActions = {
  pullStudentFees: (student: IStudent, school: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_STUDENT_FEES,
    payload: { student, school }
  }),
  setStudentFees: (studentFees: IStudentFee[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_STUDENT_FEES,
    payload: { studentFees }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_STUDENT_FEES,
    function* pullStudentFeesSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(studentFeesActions.setPhase('loading'));

      const { student, school } = payload;
      const url = `${STUDENT_FEES_API_URL}.json?studentId=${student.id}&school=${school.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(studentFeesActions.setPhase('error'));
        return;
      }

      yield put(studentFeesActions.setStudentFees(response.data));
      yield put(studentFeesActions.setPhase('success'));
    }
  );
}
