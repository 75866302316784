import { all, spawn } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import { saga as authSagas, reducer as authReducer } from './auth';
import { saga as configSagas, reducer as configReducer } from './config';
import { saga as i18nSagas, reducer as i18nReducer } from './i18n';
import { saga as searchSagas, reducer as searchReducer } from './search';
import { saga as staticSagas, reducer as staticReducer } from './static';
import { saga as recentUpdatesSagas, reducer as recentUpdatesReducer } from './recentUpdates';
import { saga as userSagas, reducer as userReducer } from './user';

import { menusReducer, menusSagas } from 'pages/admin/menus/_store';
import { databaseReducer, databaseSagas } from 'pages/admin/databases/_store';
import { classesReducer, classesSagas } from 'pages/classes/_store';
import { developerReducer, developerSagas } from 'pages/developer/_store';
import { feedsReducer, feedsSagas } from 'pages/feeds/_store';
import { filemanagerReducer } from 'pages/filemanager/redux/reducers';
import { globalReducer, globalSagas } from 'pages/global';
import { mailReducer, mailSagas } from 'pages/mail/_store';
import { organizationReducer, organizationSagas } from 'pages/organization/_store';
import { personnelReducer, personnelSagas } from 'pages/personnel/_store';
import { schoolReducer, schoolSagas } from 'pages/school/_store';
import { storeReducer, storeSagas } from 'pages/store/_store';
import { studentsReducer, studentsSagas } from 'pages/students/_store';
import { usersReducer, usersSagas } from 'pages/users/_store';
import { accountCodesReducer, accountCodesSagas } from 'pages/account-codes/_store';
import { incomeExpensesReducer, incomeExpensesSagas } from 'pages/income-expense/_store';
import { installmentsReducer, installmentsSagas } from 'pages/installment/_store';
import { schedulesReducer, schedulesSagas } from 'pages/schedule/_store';
import { impersonateLogsReducer, impersonateLogsSagas } from 'pages/reports/impersonateLogs/_store';
import { companyReducer, companySagas } from 'pages/company/_store';
import { attendancesReducer, attendancesSagas } from 'pages/attendance/_store';
import { attendanceTypesReducer, attendanceTypesSagas } from 'pages/attendance/types/_store';
import { banksReducer, banksSagas } from 'pages/bank/_store';
import {
  bankAccountReducer,
  bankAccountSettingReducer,
  bankAccountSagas,
  bankAccountSettingSagas
} from 'pages/bank/accounts/_store';
import { academicReducer, academicSagas } from 'pages/academic/_store';
import { courseReducer, courseSagas } from 'pages/course/_store';
import { courseVideoReducer, courseVideoSagas } from 'pages/course/videos/_store';
import { courseAssetReducer, courseAssetSagas } from 'pages/course/assets/_store';
import { batchReducer, batchSagas } from 'pages/batch/_store';
import { homeworksReducer, homeworksSagas } from 'pages/homework/_store';
import { admissionInterviewsReducer, admissionInterviewsSagas } from 'pages/admission/_store';
import { lawyersReducer, lawyersSagas } from 'pages/lawyer/_store';
import { discoountReducer, discountSagas } from 'pages/discount/_store';
import { discountCriteriaReducer, discountCriteriaSagas } from 'pages/discount/_store';
import { formsReducer, formsSagas } from 'pages/form/_store';
import { smsReducer, smsSagas } from 'pages/sms/_store';
import { mailTemplatesReducer, mailTemplatesSagas } from 'pages/mail/template/_store';
import { scholarshipReducer, scholarshipSagas } from 'pages/scholarship/_store';
import { enrollmentReducer, enrollmentSagas } from 'pages/enrollment/_store';
import { feeSettingsReducer, feeSettingsSagas } from 'pages/course/fee-setting/_store';
import { examReducer, examSagas } from 'pages/exam/_store';
import { subjectBranchesReducer, subjectBranchesSagas } from 'pages/subject-branch/_store';
import { cipReducer, cipSagas } from 'pages/cip/_store';
import { guardianReducer, guardiansSagas } from 'pages/guardians/_store';
import { studentBehaviourReducer, studentBehaviourSagas } from 'pages/student-behaviours/_store';
import { studentFeesReducer, studentFeesSagas } from 'pages/finance/fees/_store';

export const rootReducer = combineReducers({
  auth: authReducer,
  classes: classesReducer,
  config: configReducer,
  database: databaseReducer,
  developer: developerReducer,
  feeds: feedsReducer,
  filemanager: filemanagerReducer,
  global: globalReducer,
  i18n: i18nReducer,
  mails: mailReducer,
  menus: menusReducer,
  organization: organizationReducer,
  personnel: personnelReducer,
  recentUpdates: recentUpdatesReducer,
  school: schoolReducer,
  search: searchReducer,
  static: staticReducer,
  store: storeReducer,
  students: studentsReducer,
  user: userReducer,
  users: usersReducer,
  accountCodes: accountCodesReducer,
  incomeExpenses: incomeExpensesReducer,
  installments: installmentsReducer,
  schedules: schedulesReducer,
  impersonateLogs: impersonateLogsReducer,
  companies: companyReducer,
  attendances: attendancesReducer,
  attendanceTypes: attendanceTypesReducer,
  bankAccounts: bankAccountReducer,
  banks: banksReducer,
  bankAccountSettings: bankAccountSettingReducer,
  academic: academicReducer,
  courses: courseReducer,
  courseVideos: courseVideoReducer,
  courseAssets: courseAssetReducer,
  batches: batchReducer,
  homeworks: homeworksReducer,
  admissionInterviews: admissionInterviewsReducer,
  lawyers: lawyersReducer,
  discounts: discoountReducer,
  discountCriterias: discountCriteriaReducer,
  forms: formsReducer,
  sms: smsReducer,
  mailTemplates: mailTemplatesReducer,
  scholarship: scholarshipReducer,
  enrollment: enrollmentReducer,
  feeSettings: feeSettingsReducer,
  exams: examReducer,
  subjectBranches: subjectBranchesReducer,
  cip: cipReducer,
  guardians: guardianReducer,
  studentBehaviours: studentBehaviourReducer,
  studentFees: studentFeesReducer
});

export function* rootSaga() {
  const mainSagas = [
    authSagas(),
    configSagas(),
    searchSagas(),
    i18nSagas(),
    staticSagas(),
    recentUpdatesSagas(),
    userSagas()
  ];
  const allSagas = mainSagas.concat(
    classesSagas,
    databaseSagas,
    developerSagas,
    feedsSagas,
    globalSagas,
    mailSagas,
    menusSagas,
    organizationSagas,
    personnelSagas,
    schoolSagas,
    storeSagas,
    studentsSagas,
    usersSagas,
    accountCodesSagas,
    incomeExpensesSagas,
    installmentsSagas,
    schedulesSagas,
    impersonateLogsSagas,
    companySagas,
    attendancesSagas,
    attendanceTypesSagas,
    bankAccountSagas,
    banksSagas,
    bankAccountSettingSagas,
    academicSagas,
    courseSagas,
    courseVideoSagas,
    courseAssetSagas,
    batchSagas,
    homeworksSagas,
    admissionInterviewsSagas,
    lawyersSagas,
    discountSagas,
    discountCriteriaSagas,
    formsSagas,
    smsSagas,
    mailTemplatesSagas,
    scholarshipSagas,
    enrollmentSagas,
    feeSettingsSagas,
    examSagas,
    subjectBranchesSagas,
    cipSagas,
    guardiansSagas,
    studentBehaviourSagas,
    studentFeesSagas
  );

  yield all(
    allSagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield saga;
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}
