import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { ISchool } from 'pages/organization/organization-types';
import { IAction } from 'store/store';
import { INSTALLMENTS_API_URL } from 'store/ApiUrls';
import { IStudent } from 'pages/students/_store/types';
import moment from 'moment';
import produce from 'immer';

export type TPhase = null | 'loading' | 'adding' | 'updating' | 'deleting' | 'error' | 'success';

export interface Iinstallment {
  id: number;
  school: ISchool;
  studentId: IStudent;
  amount: number;
  installmentDate: Date;
  paymentType: number;
  bankCode: number;
  explanation: string;
  isDownPayment: string;
  promissoryNo: number;
  isDonePayment: number;
  updateDate: Date;
  updaterBy: string;
  addedAt: Date;
  addedBy: string;
  course: number;
}

interface IinstallmentState {
  installments: Iinstallment[];
  phase: TPhase;
}

type TActionAllState = IinstallmentState & {
  id: number;
  installmentCount: number;
  school: ISchool;
  student: IStudent;
  installment: Iinstallment;
  installmentInfo: Partial<Iinstallment>;
};

export const actionTypes = {
  PULL_INSTALLMENTS: 'installments/PULL_INSTALLMENTS',
  PULL_DAILY_INSTALLMENTS: 'installments/PULL_DAILY_INSTALLMENTS',
  SET_INSTALLMENTS: 'installments/SET_INSTALLMENTS',
  ADD_INSTALLMENT: 'installments/ADD_INSTALLMENT',
  UPDATE_INSTALLMENT: 'installments/UPDATE_INSTALLMENT',
  DELETE_INSTALLMENT: 'installments/DELETE_INSTALLMENT',
  REMOVE_INSTALLMENT: 'installments/REMOVE_INSTALLMENT',
  SET_INSTALLMENT: 'installments/SET_INSTALLMENT',
  SET_PHASE: 'installments/SET_PHASE'
};

export const initialState: IinstallmentState = {
  installments: [],
  phase: null
};

export const installmentsSelector = createSelector(
  (state: IinstallmentState) => objectPath.get(state, ['installments', 'installments']),
  (installments: Iinstallment[]) => installments
);

export const installmentsPhaseSelector = createSelector(
  (state: IinstallmentState) => objectPath.get(state, ['installments', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'installments' },
  (
    state: IinstallmentState = initialState,
    action: IAction<TActionAllState>
  ): IinstallmentState => {
    switch (action.type) {
      case actionTypes.SET_INSTALLMENTS: {
        const { installments } = action.payload;
        return { ...state, installments };
      }
      case actionTypes.SET_INSTALLMENT: {
        const { installment } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.installments.findIndex((d) => d.id === installment.id);
          if (index > -1) {
            draftState.installments[index] = installment;
          } else {
            draftState.installments.unshift(installment);
          }
        });
      }
      case actionTypes.REMOVE_INSTALLMENT: {
        const { id } = action.payload;
        const installments = { ...state }.installments.filter((d) => d.id !== id);
        return { ...state, installments };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const installmentsActions = {
  pullDailyInstallments: (school: ISchool): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_DAILY_INSTALLMENTS,
    payload: { school }
  }),
  pullStudentInstallments: (
    student: IStudent,
    school: ISchool
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_INSTALLMENTS,
    payload: { student, school }
  }),
  setInstallments: (installments: Iinstallment[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_INSTALLMENTS,
    payload: { installments }
  }),
  addInstallment: (
    installmentInfo: Partial<Iinstallment>,
    installmentCount: number
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.ADD_INSTALLMENT,
    payload: { installmentInfo, installmentCount }
  }),
  updateInstallment: (
    installmentInfo: Partial<Iinstallment>
  ): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.UPDATE_INSTALLMENT,
    payload: { installmentInfo }
  }),
  deleteInstallment: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.DELETE_INSTALLMENT,
    payload: { id }
  }),
  removeInstallment: (id: number): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.REMOVE_INSTALLMENT,
    payload: { id }
  }),
  setInstallment: (installment: Iinstallment): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_INSTALLMENT,
    payload: { installment }
  }),
  setPhase: (phase: TPhase): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_INSTALLMENTS,
    function* pullInstallmentsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(installmentsActions.setPhase('loading'));

      const { student, school } = payload;
      const url = `${INSTALLMENTS_API_URL}.json?studentId=${student.id}&school=${school.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(installmentsActions.setPhase('error'));
        return;
      }

      yield put(installmentsActions.setInstallments(response.data));
      yield put(installmentsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.PULL_DAILY_INSTALLMENTS,
    function* pullDailyInstallmentsSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(installmentsActions.setPhase('loading'));

      const { school } = payload;
      const today = moment().format('YYYY-MM-DD');
      const url = `${INSTALLMENTS_API_URL}.json?school=${school.id}&installmentDate=${today}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(installmentsActions.setPhase('error'));
        return;
      }

      yield put(installmentsActions.setInstallments(response.data));
      yield put(installmentsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.ADD_INSTALLMENT,
    function* addInstallmentSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(installmentsActions.setPhase('adding'));

      const { installmentInfo, installmentCount } = payload;

      try {
        for (let i = 0; i < installmentCount; i++) {
          let newDate = installmentInfo.installmentDate;
          if (i > 0) {
            newDate = new Date(
              installmentInfo.installmentDate.setMonth(
                installmentInfo.installmentDate.getMonth() + 1
              )
            );
          }
          const amount = installmentInfo.amount / installmentCount;

          const response = yield axios.post(`${INSTALLMENTS_API_URL}`, {
            id: installmentInfo.id,
            school: installmentInfo.school,
            studentId: installmentInfo.studentId,
            amount: amount.toString(),
            installmentDate: newDate,
            paymentType: installmentInfo.paymentType,
            bankCode: installmentInfo.bankCode,
            explanation: installmentInfo.explanation,
            isDownPayment: installmentInfo.isDownPayment,
            addedAt: installmentInfo.addedAt,
            addedBy: installmentInfo.addedBy,
            updateDate: '0000-00-00 00:00:00',
            course: installmentInfo.course
          });

          yield put(installmentsActions.setInstallment(response.data));
        }

        yield put(installmentsActions.setPhase('success'));
      } catch (error) {
        // Update phase
        yield put(installmentsActions.setPhase('error'));
      }
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_INSTALLMENT,
    function* updateInstallmentInfo({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(installmentsActions.setPhase('updating'));

      const { installmentInfo } = payload;
      const response = yield axios.patch(`${INSTALLMENTS_API_URL}/${installmentInfo.id}`, {
        id: installmentInfo.id,
        school: installmentInfo.school,
        studentId: installmentInfo.studentId,
        amount: installmentInfo.amount.toString(),
        installmentDate: installmentInfo.installmentDate,
        paymentType: installmentInfo.paymentType,
        bankCode: installmentInfo.bankCode,
        explanation: installmentInfo.explanation,
        isDownPayment: installmentInfo.isDownPayment,
        promissoryNo: installmentInfo.promissoryNo,
        isDonePayment: installmentInfo.isDonePayment,
        updateDate: installmentInfo.updateDate,
        updaterBy: installmentInfo.updaterBy,
        addedAt: installmentInfo.addedAt,
        addedBy: installmentInfo.addedBy
      });

      if (response.status !== 200) {
        yield put(installmentsActions.setPhase('error'));
        return;
      }

      yield put(installmentsActions.setInstallment(response.data));
      yield put(installmentsActions.setPhase('success'));
    }
  );

  yield takeLatest(
    actionTypes.DELETE_INSTALLMENT,
    function* deleteInstallmentSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(installmentsActions.setPhase('deleting'));

      const { id } = payload;
      const response = yield axios.delete(`${INSTALLMENTS_API_URL}/${id}`);

      if (response.status !== 204) {
        yield put(installmentsActions.setPhase('error'));
        return;
      }

      yield put(installmentsActions.removeInstallment(id));
      yield put(installmentsActions.setPhase('success'));
    }
  );
}
