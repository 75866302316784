import { TMenuType } from '../pages/admin/menus/menu-types';
import { generateApiLegacyUrl } from '../utils/Helpers';
import { TLang } from '../utils/shared-types';

// Global Api Url
export const globalApiUrl = 'https://api.schoost.com';

// Base Url for backend api
export const SCHOOST_API_URL =
  (process.env.NODE_ENV === 'production' &&
    window &&
    window.location &&
    generateApiLegacyUrl(window.location.host, 'api')) ||
  process.env.REACT_APP_SCHOOST_API_URL;

export const backendBaseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://' + SCHOOST_API_URL
    : process.env.REACT_APP_SCHOOST_API_URL;
export const localeBaseUrl = `${backendBaseUrl}/{lang}`;
export const apiBaseUrl = `${backendBaseUrl}/api`;

// Backend Urls
export const CONFIGURATION_URL = localeBaseUrl + '/configuration';
export const CREATE_SHORT_LINK_URL = localeBaseUrl + '/create-short-link';
export const DASHBOARD_BASIC_STATS_URL = localeBaseUrl + '/dashboard/{schoolId}/basics/stats';
export const DASHBOARD_DAILY_CASH_STATS_URL =
  localeBaseUrl + '/dashboard/{schoolId}/daily-cash/stats/{period}';
export const DASHBOARD_ENROLLMENT_STATS_URL =
  localeBaseUrl + '/dashboard/{schoolId}/enrollment/stats/{period}';
export const DASHBOARD_ENROLLMENT_OVERVIEW_URL =
  localeBaseUrl + '/dashboard/{schoolId}/enrollment/overview/{schoolId}';
export const DASHBOARD_HELPDESK_INCIDENTS_URL =
  localeBaseUrl + '/dashboard/{schoolId}/helpdesk/incidents';
export const DASHBOARD_SCHOOL_USAGE_STATS_URL = localeBaseUrl + '/dashboard/school-usage/stats';
export const FEEDS_LANG_API_URL = localeBaseUrl + '/feeds';
export const FILEMANAGER_USER_URL = localeBaseUrl + '/filemanager/users/{userId}';
export const FILEMANAGER_SCHOOL_URL = localeBaseUrl + '/filemanager/schools/{schoolId}';
export const RESET_PASSWORD_URL = localeBaseUrl + '/reset-password';
export const SCHOOL_CONFIGURATION_URL = localeBaseUrl + '/schools/{schoolId}/configuration';
export const SEARCH_URL = localeBaseUrl + '/search/{userId}/{context}';
export const SEASONS_URL = localeBaseUrl + '/seasons/copy-data';
export const MENUS_LANG_API_URL = localeBaseUrl + '/menus';
export const ONLINE_CLASSES_URL = localeBaseUrl + '/online-classes';
export const STORE_TOKEN_API_URL = localeBaseUrl + '/stores/{schoolId}/token';
export const STORE_CUSTOMER_TOKEN_URL = localeBaseUrl + '/stores/{schoolId}/customer-token';
export const USER_GENERATE_API_KEY_URL = localeBaseUrl + '/users/{userId}/generate-api-key';
export const USER_LOGIN_URL = localeBaseUrl + '/login';
export const USER_MENUS_URL = localeBaseUrl + '/users/{userId}/{schoolId}/menus';
export const USER_NOTIFICATIONS_URL = localeBaseUrl + '/users/{userId}/notifications';
export const USER_PERSONAL_URL = localeBaseUrl + '/users/{userId}/personal';
export const USER_SCHOOLS_URL = localeBaseUrl + '/users/{userId}/schools';
export const USER_STUDENTS_URL = localeBaseUrl + '/users/{userId}/students';
export const USER_TRANSFER_SCHOOLS_URL = localeBaseUrl + '/users/{userId}/transfer-schools';
export const VERIFY_EMAIL_URL = localeBaseUrl + '/verify-email';

// Api platform urls
export const ACADEMIC_REPORT_TYPES_API_URL = apiBaseUrl + '/ac_definitions';
export const ACADEMIC_REPORT_STATIC_TEXT_API_URL = apiBaseUrl + '/ac_definition_statics';
export const ACCOUNT_CODES_API_URL = apiBaseUrl + '/account_codes';
export const ACCOUNTING_SETTINGS_API_URL = apiBaseUrl + '/accounting_settings';
export const ADMISSION_INTERVIEWS_API_URL = apiBaseUrl + '/admission_interviews';
export const ATTENDANCES_API_URL = apiBaseUrl + '/attendances';
export const ATTENDANCE_TYPES_API_URL = apiBaseUrl + '/attendance_types';
export const BANKS_API_URL = apiBaseUrl + '/banks';
export const BANK_BRANCHES_API_URL = apiBaseUrl + '/bank_branches';
export const BANK_ACCOUNTS_API_URL = apiBaseUrl + '/bank_accounts';
export const BANK_ACCOUNTS_SETTINGS_API_URL = apiBaseUrl + '/bank_account_settings';
export const BATCHES_API_URL = apiBaseUrl + '/batches';
export const BLOOD_GROUPS_API_URL = apiBaseUrl + '/blood_groups';
export const CLASS_BATCHES_API_URL = apiBaseUrl + '/class_batches';
export const CLASS_TYPES_API_URL = apiBaseUrl + '/teacher_class_types';
export const CLASSES_API_URL = apiBaseUrl + '/classses';
export const CLASS_TEACHERS_URL = apiBaseUrl + '/class_teachers';
export const CIP_ACTIVITIES_API_URL = apiBaseUrl + '/ssp_activities';
export const CIP_SEASONS_API_URL = apiBaseUrl + '/cip_seasons';
export const COMMENTS_API_URL = apiBaseUrl + '/comments';
export const COMPANIES_API_URL = apiBaseUrl + '/companies';
export const CONFIGURATIONS_API_URL = apiBaseUrl + '/configurations';
export const COURSE_API_URL = apiBaseUrl + '/courses';
export const COURSE_ASSET_API_URL = apiBaseUrl + '/course_assets';
export const COURSE_VIDEOS_API_URL = apiBaseUrl + '/course_videos';
export const DATABASES_API_URL = apiBaseUrl + '/season_databases';
export const DELETE_PERSONAL_DATA_REQUEST_URL = apiBaseUrl + '/delete_personal_data_requests';
export const DEVICES_URL = apiBaseUrl + '/devices';
export const DISCOUNTS_API_URL = apiBaseUrl + '/discounts';
export const DISCOUNT_CRITERIAS_API_URL = apiBaseUrl + '/discount_criterias';
export const ENROLLMENT_AGREEMENT_API_URL = apiBaseUrl + '/enrollment_agreements';
export const ENROLLMENT_AGREEMENT_ITEM_API_URL = apiBaseUrl + '/enrollment_agreement_items';
export const ENROLLMENT_DOCUMENTS_API_URL = apiBaseUrl + '/enrollment_documents';
export const EXAM_SCORE_CATEGORIES_API_URL = apiBaseUrl + '/exam_score_categories';
export const EXAM_TEMPLATES_API_URL = apiBaseUrl + '/exam_templates';
export const EXAM_TEMPLATE_SUBJECTS_API_URL = apiBaseUrl + '/exam_template_subjects';
export const FREQUENT_MENUS_API_URL = apiBaseUrl + '/frequent_menus';
export const FEEDS_API_URL = apiBaseUrl + '/feeds';
export const FACEBOOK_USERS_URL = apiBaseUrl + '/facebook_users';
export const FILES_API_URL = apiBaseUrl + '/files';
export const FORMS_API_URL = apiBaseUrl + '/forms';
export const FORM_SETTINGS_API_URL = apiBaseUrl + '/form_settings';
export const HOMEWORKS_API_URL = apiBaseUrl + '/homework';
export const IMPERSONATE_LOGS_API_URL = apiBaseUrl + '/impersonate_logs';
export const INCOME_EXPENSE_API_URL = apiBaseUrl + '/income_expenses';
export const INSTALLMENTS_API_URL = apiBaseUrl + '/installments';
export const GOOGLE_USERS_URL = apiBaseUrl + '/google_users';
export const GRADE_LEVEL_API_URL = apiBaseUrl + '/grade_levels';
export const GRADING_TERMS_API_URL = apiBaseUrl + '/grading_terms';
export const GUARDIANS_API_URL = apiBaseUrl + '/guardians';
export const LAWYERS_API_URL = apiBaseUrl + '/lawyers';
export const LIKES_API_URL = apiBaseUrl + '/likes';
export const MAILS_API_URL = apiBaseUrl + '/email_users';
export const MAIL_TEMPLATES_API_URL = apiBaseUrl + '/email_templates';
export const MESSAGE_PARAMETERS_API_URL = apiBaseUrl + '/message_parameters';
export const MENUS_API_URL = apiBaseUrl + '/menus';
export const ONLINE_CLASSES_API_URL = apiBaseUrl + '/online_classes';
export const OPTIC_FORM_TEMPLATES_API_URL = apiBaseUrl + '/optic_form_templates';
export const OPTIC_FORM_TEMPLATE_FIELDS_API_URL = apiBaseUrl + '/optic_form_template_fields';
export const OPTIC_FORM_FIELD_NAMES_API_URL = apiBaseUrl + '/optic_form_field_names';
export const PAYMENT_METHODS_API_URL = apiBaseUrl + '/payment_methods';
export const PERSONNEL_API_URL = apiBaseUrl + '/personnels';
export const PERSONNEL_CATEGORIES_API_URL = apiBaseUrl + '/personnel_categories';
export const PERSONNEL_DEPARTMENTS_API_URL = apiBaseUrl + '/personnel_departments';
export const READONLY_ENROLLMENT_CONTRACT_API_URL = apiBaseUrl + '/readonly_enrollment_contracts';
export const REGISTER_EMAIL_API_URL = apiBaseUrl + '/register_emails';
export const SCHOOLS_API_URL = apiBaseUrl + '/schools';
export const SCHOLARSHIP_DEFINITIONS_API_URL = apiBaseUrl + '/scholarship_definitions';
export const SCHEDULES_API_URL = apiBaseUrl + '/schedules';
export const SEASONS_API_URL = apiBaseUrl + '/seasons';
export const SMS_TEMPLATES_API_URL = apiBaseUrl + '/sms_templates';
export const STUDENTS_API_URL = apiBaseUrl + '/students';
export const STUDENT_BEHAVIOURS_API_URL = apiBaseUrl + '/student_behaviours';
export const STUDENT_FEES_API_URL = apiBaseUrl + '/student_fees';
export const STUDENT_TAGS_API_URL = apiBaseUrl + '/student_tags';
export const SUBJECT_BRANCHES_API_URL = apiBaseUrl + '/subject_branches';
export const USERS_API_URL = apiBaseUrl + '/users';
export const USERS_TYPES_API_URL = apiBaseUrl + '/user_types';

// Global Api Urls
export const GLOBAL_CITIES_URL = globalApiUrl + '/cities';
export const GLOBAL_COUNTRIES_URL = globalApiUrl + '/countries';
export const GLOBAL_LOCALES_URL = globalApiUrl + '/locales';
export const GLOBAL_MENUS_URL = globalApiUrl + '/menus';
export const GLOBAL_STATES_URL = globalApiUrl + '/states';
export const GLOBAL_UPDATES_URL = globalApiUrl + '/updates';

interface IUpdateUrlOptions {
  lang?: TLang;
  userId?: string;
  schoolId?: number;
  period?: string;
  menuType?: TMenuType;
  context?: string;
}

export const updateApiUrl = (url: string, options: IUpdateUrlOptions): string => {
  Object.keys(options).forEach((key) => {
    if (typeof key !== 'undefined') {
      url = url.replace('{' + key + '}', options[key]);
    }
  });

  return url;
};

// Logos
export const smartclassLogo = 'https://schst.in/smartclass';
export const schoostLogo = 'https://schst.in/schoost';
export const schoostFavicon = 'https://schst.in/icon';
