import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import objectPath from 'object-path';
import { put, takeLatest } from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import axios from 'axios';
import { IAction } from 'store/store';
import { COURSE_API_URL } from 'store/ApiUrls';
import { ISchool, ISeason } from 'pages/organization/organization-types';

export interface ICourse {
  id?: number;
  feeId?: number;
  courseTitle?: string;
  slug?: string;
  courseExplanation?: string;
  coursePicture?: string;
  seasonId: number;
  schoolId: number;
}

interface ICourseState {
  courses: ICourse[];
  phase: string;
}

type TActionAllState = ICourseState & {
  id: number;
  school: ISchool;
  season: ISeason;
  course: ICourse;
  courseInfo: Partial<ICourse>;
};

export const actionTypes = {
  PULL_COURSES: 'course/PULL_COURSES',
  SET_COURSES: 'course/SET_COURSES',
  SET_PHASE: 'course/SET_PHASE'
};

export const initialState: ICourseState = {
  courses: [],
  phase: null
};

export const courseSelector = createSelector(
  (state: ICourseState) => objectPath.get(state, ['courses', 'courses']),
  (courses: ICourse[]) => courses
);

export const coursePhaseSelector = createSelector(
  (state: ICourseState) => objectPath.get(state, ['courses', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'courses' },
  (state: ICourseState = initialState, action: IAction<TActionAllState>): ICourseState => {
    switch (action.type) {
      case actionTypes.SET_COURSES: {
        const { courses } = action.payload;
        return { ...state, courses };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const coursesActions = {
  pullCourses: (school: ISchool, season: ISeason): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.PULL_COURSES,
    payload: { school, season }
  }),
  setCourses: (courses: ICourse[]): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_COURSES,
    payload: { courses }
  }),
  setPhase: (phase: string): IAction<Partial<TActionAllState>> => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    actionTypes.PULL_COURSES,
    function* pullCourseSaga({ payload }: IAction<Partial<TActionAllState>>) {
      yield put(coursesActions.setPhase('loading'));

      const { school, season } = payload;

      const url = `${COURSE_API_URL}.json?school=${school.id}&season=${season.id}&pagination=false`;
      const response = yield axios.get(url);

      if (response.status !== 200) {
        yield put(coursesActions.setPhase('error'));
        return;
      }

      yield put(coursesActions.setCourses(response.data));
      yield put(coursesActions.setPhase('success'));
    }
  );
}
